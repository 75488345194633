import React from 'react'
import TestimonialInner from '~/components/molecules/Testimonial'
import FadeInWhenVisible from '~/components/FadeInWhenVisible'

export default function Testimonial({key, index, ...props}) {

  let alignBackground = 'top-0'
  let backgroundSize = 'full'

  const section = props.testimonial
  const backgroundColour = section?.backgroundColour
  const backgroundColourAlign = backgroundColour?.alignColour
  const backgroundColourSize = backgroundColour?.colourSize

  {backgroundColourAlign === 'bottom' && (
      alignBackground = 'bottom-0'
  )}

  return (
    <FadeInWhenVisible id={section.fieldGroupName + index}  className={`${backgroundColour?.colour ? `${backgroundColour?.padding?.top === 'normal' && 'pt-0 md:pt-36'} ${backgroundColour?.padding?.bottom === 'normal' && 'pb-0 md:pb-36'}` : 'my-0 lg:my-36'}  relative`}>
      {backgroundColour && (
        <div className={`absolute left-0 right-0 bg-${backgroundColour.colour} ${alignBackground} h-${backgroundColourSize}`}></div>
      )}
      <div className="container relative  px-0 lg:px-16">
        <TestimonialInner {...section} />
      </div>
    </FadeInWhenVisible>
  )
}
